import ScrollManager from 'window-scroll-manager';
import { getElementOffsetTop, LARGE_SCREEN_BREAKPOINT } from './utils.js';

const ANIMATION_OFFSET = 75;
const ANIMATION_DURATION = 250;

export default function animationFunctions() {
  const flowerGround = document.querySelector('.Rsvp-flower--ground');
  let flowerGroundOffsetTop = getElementOffsetTop(flowerGround);
  const rockGround = document.querySelector('.Rsvp-rock--ground');
  let rockGroundOffsetTop = getElementOffsetTop(rockGround);
  const rock = document.querySelector('.Rsvp-rock--svg');


  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  let startingBp = windowWidth > LARGE_SCREEN_BREAKPOINT ? 'lg' : 'sm';

  (function setInitialAnimation() {
    if (startingBp === 'sm') {
      return null;
    }

    const scrollPosition = window.pageYOffset;

    setScaleValue(flowerGround, flowerGroundOffsetTop, scrollPosition);
    setScaleValue(rockGround, rockGroundOffsetTop, scrollPosition);
  })();

  setAnimationTriggers();

  function setScaleValue(element, elementOffset, scrollValue) {
    let scaleValue = (scrollValue - (elementOffset - windowHeight + ANIMATION_OFFSET)) / ANIMATION_DURATION;

    if(scrollValue < (elementOffset - windowHeight + ANIMATION_OFFSET)) {
      scaleValue = 0;
    }

    // clamp value from 0 to 1;
    scaleValue = Math.min(1, Math.max(0, scaleValue));
    element.style.transform = `scaleX(${ scaleValue })`;
  }

  window.addEventListener('resize', function(e) {
    windowWidth = window.innerWidth;

    let breakpoint = windowWidth > LARGE_SCREEN_BREAKPOINT ? 'lg' : 'sm';

    // breakpoint has changed
    if (startingBp !== breakpoint) {
      startingBp = breakpoint;
      if (startingBp === 'lg') {
        flowerGround.style.transform = 'scaleX(0)';
        rockGround.style.transform = 'scaleX(0)';
        rock.classList.remove("Rsvp-rock--show");
        flowerGroundOffsetTop = getElementOffsetTop(flowerGround);
        rockGroundOffsetTop = getElementOffsetTop(rockGround);
      } else {
        flowerGround.style.transform = 'scaleX(1)';
        rockGround.style.transform = 'scaleX(1)';
      }

      window.scrollTo(0, 0);
    }
  })

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();

    // 25 is added to get last frame before element goes from viewport if user is scrolling too fast
    return (
      rect.top >= 0 && rect.bottom <= (window.innerHeight - ANIMATION_OFFSET + 25)
    );
  }

  function setAnimationTriggers() {
    const sm = new ScrollManager();

    let scrollPositionOfFlowerGround;
    let scrollPositionOfRockGround;

    window.addEventListener('window-scroll', function(e) {
      // no animations on small breakpoint
      if (startingBp === 'sm') {
        return null
      }

      requestAnimationFrame(function() {
        if (isElementInViewport(flowerGround)) {
          setScaleValue(flowerGround, flowerGroundOffsetTop, e.detail.scrollPositionY)
        }

        if (isElementInViewport(rockGround)) {
          setScaleValue(rockGround, rockGroundOffsetTop, e.detail.scrollPositionY);

          if (rockGround.style.transform === 'scaleX(1)') {
            rock.classList.add("Rsvp-rock--show");
          } else {
            rock.classList.remove("Rsvp-rock--show");
          }
        }
      });
    });
  }
}
