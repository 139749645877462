import formFunctions from "./form.js";
import animationFunctions from "./animation.js";
import navButton from "./navigateButton.js";

// add browser name

window.onload = function() {
  formFunctions();
  animationFunctions();
  navButton();
};
