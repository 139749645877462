import ScrollManager from "window-scroll-manager";
import animateScrollTo from "animated-scroll-to";
import {
  getElementOffsetTop,
  LARGE_SCREEN_BREAKPOINT,
  MEDIUM_SCREEN_BREAKPOINT
} from "./utils.js";

const NAV_BUTTON_BOTTOM_OFFSET_MD = 52;
const NAV_BUTTON_BOTTOM_OFFSET_SM = 30;

const ANIMATION_DURATION_LG = 100;
const ANIMATION_DURATION_SM = 40;

export default function navButton() {
  const navButton = document.querySelector(".Rsvp-link");
  const formRsvpButton = document.querySelector(".Rsvp-form--title");

  let windowHeight = document.documentElement.clientHeight;
  let windowWidth = window.innerWidth;
  let formOffsetTop = getElementOffsetTop(formRsvpButton);

  const scrollPosition = window.pageYOffset;

  let breakpoint = windowWidth > MEDIUM_SCREEN_BREAKPOINT ? "md" : "sm";
  let elementOffset =
    breakpoint === "sm"
      ? NAV_BUTTON_BOTTOM_OFFSET_SM
      : NAV_BUTTON_BOTTOM_OFFSET_MD;
  let animationDuration =
    breakpoint === "sm" ? ANIMATION_DURATION_SM : ANIMATION_DURATION_LG;

  // if refresh is done on end of page
  if (scrollPosition + windowHeight - elementOffset > formOffsetTop) {
    navButton.style.display = "none";
  }

  window.addEventListener("resize", function(e) {
    windowWidth = document.documentElement.clientWidth;

    breakpoint = windowWidth > MEDIUM_SCREEN_BREAKPOINT ? "md" : "sm";
    elementOffset =
      breakpoint === "sm"
        ? NAV_BUTTON_BOTTOM_OFFSET_SM
        : NAV_BUTTON_BOTTOM_OFFSET_MD;
    animationDuration =
      breakpoint === "sm" ? ANIMATION_DURATION_SM : ANIMATION_DURATION_LG;
    formOffsetTop = getElementOffsetTop(formRsvpButton);
    windowHeight = document.documentElement.clientHeight;
  });

  const sm = new ScrollManager();

  window.addEventListener("window-scroll", function(e) {
    const scrollValue = e.detail.scrollPositionY;

    if (scrollValue + windowHeight - elementOffset < formOffsetTop) {
      navButton.style.opacity = 1;
    }

    if (scrollValue + windowHeight - elementOffset > formOffsetTop) {
      let opacityValue =
        1 +
        (formOffsetTop - windowHeight + elementOffset - scrollValue) /
          animationDuration;
      opacityValue = Math.min(1, Math.max(0, opacityValue));
      navButton.style.opacity = opacityValue;
      navButton.style.display = "block";

      if (navButton.style.opacity === "0") {
        navButton.style.display = "none";
      }
    }
  });

  navButton.addEventListener("click", e => {
    animateScrollTo(formRsvpButton);
  });
}
