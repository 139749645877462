export function getElementOffsetTop(el) {
  let top = 0;
  let element = el;

  do {
    top += element.offsetTop || 0;
    element = element.offsetParent;
  } while (element);

  return top;
}

export const EMAIL_REGEX = /^(?!.*\.{2})(?!.*["_%+-.]@)[a-zA-Z0-9"]+[a-zA-Z0-9"_%+-.?]+@[a-zA-Z0-9]+[a-zA-Z0-9-]+(?:\.[a-zA-Z\d\-]+)+([a-zA-Z\d\-])$/i;

export const LARGE_SCREEN_BREAKPOINT = 1200;
export const MEDIUM_SCREEN_BREAKPOINT = 600;
