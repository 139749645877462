import { EMAIL_REGEX } from "./utils.js";

export default function formFunctions() {
  const form = document.querySelector(".Rsvp-form");
  const select = document.querySelector(".Rsvp-select");
  const selectValue = document.querySelector(".Rsvp-select--label");
  const submitButton = document.querySelector(".Rsvp-form--submit");

  select.addEventListener("change", changeLabel);

  const validator = {
    valid: {
      firstName: false,
      lastName: false,
      email: false,
      company: false,
      noRsvp: false
    },
    touched: {
      firstName: false,
      lastName: false,
      email: false,
      company: false,
      noRsvp: false
    },
    correctEmail: true,
    realTimeValid: {
      firstName: false,
      lastName: false,
      email: false,
      company: false,
      noRsvp: false
    },
    formValid: false
  };

  function changeLabel(e) {
    selectValue.innerHTML = e.target.value;
    selectValue.classList.add("Rsvp-select--labelBlack");
  }

  const inputNameTags = [
    {
      nameAttribute: "first_name",
      field: "firstName"
    },
    {
      nameAttribute: "last_name",
      field: "lastName"
    },
    {
      nameAttribute: "email",
      field: "email"
    },
    {
      nameAttribute: "company",
      field: "company"
    }
  ];

  const selectField = {
    domElement: document.querySelector(".Rsvp-select"),
    field: "noRsvp"
  };

  const inputFields = inputNameTags.map(({ nameAttribute, field }) => {
    return {
      domElement: document.querySelector(`input[name=${nameAttribute}]`),
      field: field
    };
  });

  inputFields.push(selectField);

  inputFields.forEach(({ domElement, field }) => {
    domElement.addEventListener("focus", e => {
      const errorText = document.querySelector(`.Rsvp-error--${field}`);
      errorText.classList.remove("Rsvp-error--show");
      domElement.classList.remove("Rsvp-input--error");
    });

    domElement.addEventListener("blur", e => {
      validator.touched[field] = true;
      const value = e.target.value;

      validateField(field, value);

      if (!validator.valid[field]) {
        const errorText = document.querySelector(`.Rsvp-error--${field}`);
        errorText.classList.add("Rsvp-error--show");

        if (field === "email") {
          errorText.innerHTML = "Required";
        }

        if (field === "email" && !validator.correctEmail) {
          domElement.classList.add("Rsvp-input--error");
          errorText.innerHTML = "Invalid";
        }
      }
    });

    domElement.addEventListener("keyup", e => {
      const elementValue = e.target.value;
      if (field === "email") {
        const validRegex = EMAIL_REGEX.test(elementValue) ? true : false;
        validator.realTimeValid[field] =
          validRegex && elementValue ? true : false;
      } else {
        validator.realTimeValid[field] = elementValue ? true : false;
      }

      validateForm();
    });

    if (field === "noRsvp") {
      domElement.addEventListener("change", e => {
        const elementValue = e.target.value;
        validator.realTimeValid[field] = elementValue ? true : false;
        // change focus of select because of ie
        selectValue.focus();

        validateForm();
      });
    }
  });

  function validateField(field, value) {
    if (field === "email") {
      if (value) {
        validator.correctEmail = EMAIL_REGEX.test(value) ? true : false;
        validator.valid[field] = validator.correctEmail && value ? true : false;
      } else {
        validator.valid[field] = value ? true : false;
        validator.correctEmail = true;
      }
    } else {
      // ie shows hidden option, and by default this is the value of select
      if (value === "Select option") {
        validator.valid[field] = false;
      } else {
        validator.valid[field] = value ? true : false;
      }
    }
  }

  function validateForm() {
    validator.formValid = Object.values(validator.realTimeValid).includes(false)
      ? false
      : true;
    submitButton.disabled = validator.formValid ? false : true;
  }
}
